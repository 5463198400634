import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { open_snack_ac } from '../../actions/snack.ac';
import BoxMan from './components/Boxman.js';
import PastTracks from './components/PastTracks.js';
import WoSearch from './components/WoSearch.js';
import Zebra from './components/ZebraPrint.js';

function GSOTracking(props) {
  const { me } = props;
  const [wo, setWo] = useState({});
  const [ships, setShips] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  // const [deviceList] = useState([]);

  const checkWo = async (event) => {
    if (event.keyCode !== 13) return;

    let won = document.querySelector('#wo').value.trim();
    let type = 'get_gso_wo';
    if (won.charAt(0).toUpperCase() === 'T') {
      type = 'get_transfer_order';
      won = won.substr(1, won.length);
    }

    if (Number(won) > 999999999999) {
      props.open_snack_ac({
        variant: 'error',
        message:
          "that work order id is too big, \ni'm gonna let you put it in. \nBut it's going to fail.",
      });
    }
    try {
      const url = '/api/gso/' + type;
      let res = await window.sch.post(url, { _id: won });
      setWo(res.wo);
    } catch (error) {
      props.open_snack_ac({
        variant: 'error',
        message: error.message,
      });
    }
  };

  const cancelTracks = async () => {
    try {
      const url = '/api/gso/cancelTracks';
      const data = {
        _id: wo._id,
        trackingNumbers: wo.trackingNumbers,
      };
      await window.sch.post(url, data);
      resetWo();
    } catch (e) {
      let msg = 'unkown error';
      if (e.response && e.response.data) {
        msg = e.response.data.error;
      }

      props.open_snack_ac({
        variant: 'error',
        message: msg,
      });
    }
  };

  const updateWoField = (val, field) => {
    let updatedWo = { ...wo };
    updatedWo[field] = val.target.value;
    setWo(updatedWo);
  };

  const createShips = async (pkgs) => {
    let updatedWo = { ...wo };
    updatedWo.pkgs = pkgs;

    try {
      const url = '/api/gso/createShip';
      let res = await window.sch.post(url, updatedWo);
      setShips(res);
      setWo(updatedWo);
    } catch (e) {
      let msg = 'unable to generate shipping label';
      if (e.response && e.response.data) {
        msg = e.response.data.error;
      }
      props.open_snack_ac({
        variant: 'error',
        message: msg,
      });
    }
  };

  const validatePosInt = (val) => {
    let t = '';
    if (val.zipcode && val.target.value) {
      val.target.value = Number(val.target.value.toString().substr(0, 5));
    }
    if (!val.zipcode && val.target.value.charAt(0).toUpperCase() === 'T') {
      val.target.value = val.target.value.substr(1, val.target.value.length);
      t = 'T';
    }
    if (
      val.target.value !== null &&
      !Number.isNaN(val.target.value) &&
      Number(val.target.value) < 0
    ) {
      return (val.target.value = 0);
    }
    if (val.target.value !== null && !Number.isNaN(val.target.value)) {
      return (val.target.value = t + val.target.value.replace(/\D/g, ''));
    }
  };

  const resetWo = () => {
    setWo({});
  };

  const getPrinters = async () => {
    return new Promise((resolve, reject) => {
      if (deviceList.length !== 0) {
        return resolve(deviceList);
      } else {
        window.BrowserPrint.getLocalDevices(
          (device_list) => {
            console.log('Device List: ', device_list);
            setDeviceList(device_list);
            return resolve(deviceList);
          },
          () => {
            alert('Error getting local devices');
            reject('Error getting local devices');
          },
          'printer'
        );
      }
    });
  };

  const print_one = async (dev, zpl) => {
    return new Promise((resolve, reject) => {
      dev.send(
        zpl,
        (res) => {
          return resolve(res);
        },
        (err) => {
          return reject(err);
        }
      );
    });
  };

  const print = async () => {
    // let start = new Date().getTime();
    getPrinters()
      .then((device) => {
        return Promise.mapSeries(ships, (ship) => {
          return print_one(device[0], ship.response.ThermalLabel);
        }).then((res) => {
          refreshPage();
          return;
        });
      })
      .catch((err) => {
        props.open_snack_ac({
          variant: 'error',
          message: 'unable to print, check console for err',
        });
        refreshPage();
      });
  };

  const refreshPage = () => {
    setShips([]);
    setWo({});
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `/BrowserPrint-2.0.0.75.min.js`;
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      console.log('Script Loaded!');
      getPrinters();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!me.user.roles.includes('warehouse')) {
    return (
      <div>
        <h1>Access Denied</h1>
        <p>You must have 'warehouse' role to access this page</p>
      </div>
    );
  }

  return (
    <div className="padit">
      {ships.length !== 0 ? (
        <Zebra
          wo={wo}
          print={print}
          refreshPage={refreshPage}
          deviceList={deviceList}
        />
      ) : wo._id !== undefined &&
        (wo.trackingNumbers === undefined ||
          wo.trackingNumbers.length === 0) ? (
        <BoxMan
          data={wo}
          createShips={createShips}
          updateWoField={updateWoField}
          validatePosInt={validatePosInt}
          openSnack={props.open_snack_ac}
        />
      ) : wo.trackingNumbers !== undefined && wo.trackingNumbers.length > 0 ? (
        <PastTracks
          data={wo}
          updateWoField={updateWoField}
          resetWo={resetWo}
          validatePosInt={validatePosInt}
          cancelTracks={cancelTracks}
        />
      ) : (
        <WoSearch checkWo={checkWo} validatePosInt={validatePosInt} />
      )}
    </div>
  );
}

const mapDispatchToProps = {
  open_snack_ac,
};
const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
});

export default connect(mapStateToProps, mapDispatchToProps)(GSOTracking);
