import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  withStyles,
  Select,
  Chip,
  MenuItem,
  OutlinedInput,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';

import { Loading } from '../../HourlyWos/components';
import { open_snack_ac } from '../../../actions/snack.ac';
// import bcrypt from 'bcryptjs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const API_ENDPOINTS = {
  GET_PLACES: '/api/dt/places',
  GET_DRIVER_DETAIL_BY_ID: '/api/dt/drivers',
  UPDATE_DRIVER_DETAIL: '/api/dt/update_driver',
  CREATE_DRIVER_DETAIL: '/api/driver/create_driver',
};

const styles = () => ({
  form: {
    width: '100%',
    marginBottom: 10,
  },
  submitButton: {
    marginTop: 10,
  },

  label: {
    position: 'absolute',
    padding: '18.5px 14px',
    fontSize: 14,
  },
  labelShrink: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    position: 'absolute',
    top: 0,
    left: 20,
    zIndex: 1,
    background: 'white',
    width: 60,
    textAlign: 'center',
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const roles = [
  'admin',
  'inactive',
  'dispatcher',
  'oncall',
  'courier',
  'no_phone',
  'driver',
];

const EditDriverForm = (props) => {
  const { classes, open_snack_ac } = props;

  const [dc, setDc] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [driverDetail, setDriverDetail] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const driverId = props.location.pathname.split('/').pop();

  const isCreateDriver =
    props.location.pathname.split('/').pop() === 'create-driver';

  useEffect(() => {
    const defaultFormData = isCreateDriver
      ? { roles: ['oncall', 'driver'] }
      : driverDetail;

    setFormData(defaultFormData);
  }, [driverDetail, isCreateDriver]);

  useEffect(() => {
    if (!isCreateDriver) {
      fetchDriverDetailById();
    }
    fetchPlaces();
    // eslint-disable-next-line
  }, []);

  const fetchPlaces = async () => {
    try {
      const { place } = await window.sch.get(API_ENDPOINTS.GET_PLACES);
      setDc(place);
    } catch (err) {
      handleApiError('Error fetching locations');
    }
  };

  const fetchDriverDetailById = async () => {
    try {
      setIsLoading(true);
      const { driver } = await window.sch.get(
        `${API_ENDPOINTS.GET_DRIVER_DETAIL_BY_ID}/${driverId}`
      );

      setDriverDetail(driver);
    } catch (err) {
      handleApiError('Error fetching driver details');
    } finally {
      setIsLoading(false);
    }
  };

  async function updateDriverDetail(formData) {
    const data = await window.sch.post(API_ENDPOINTS.UPDATE_DRIVER_DETAIL, {
      driver: formData,
    });
    return data;
  }

  async function createDriver(formData) {
    const data = await window.sch.post(API_ENDPOINTS.CREATE_DRIVER_DETAIL, {
      driver: formData,
    });
    return data;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'roles') {
      setMenuOpen(false);
    }

    if (name === 'email') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setErrors((prev) => ({
        ...prev,
        email: isValidEmail ? '' : 'Invalid email address',
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const submitDriverDetail = async () => {
    if (!validateForm()) {
      return;
    }

    const message = isCreateDriver
      ? 'Driver created sucessfully'
      : `${formData.driver_nm} detail updated sucessfully`;

    try {
      setIsSubmitting(true);
      if (isCreateDriver) {
        const body = {
          ...formData,
          driver_id: parseInt(formData.driver_id),
        };
        await createDriver(body);
      } else {
        await updateDriverDetail(formData);
      }

      props.open_snack_ac({
        variant: 'success',
        message,
      });
      props.history.push('/admin/dtrack/drivers');
    } catch (err) {
      setIsSubmitting(false);
      props.open_snack_ac({
        variant: 'error',
        message: err.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteRole = (role) => {
    const newRoles = formData.roles.filter((r) => r !== role);

    setFormData({
      ...formData,
      roles: newRoles,
    });
  };

  const handleApiError = (message) => {
    open_snack_ac({
      variant: 'error',
      message,
    });
  };

  const validateForm = () => {
    const requiredFields = {
      driver_id: 'Driver’s HDMS Op ID is required',
      _id: 'ID is required',
      driver_nm: 'Name is required',
      email: 'Email is required',
      ph: 'Phone is required',
    };

    const newErrors = Object.entries(requiredFields).reduce(
      (errors, [field, errorMessage]) => {
        if (!formData[field]) {
          errors[field] = errorMessage;
        }
        return errors;
      },
      {}
    );

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  return (
    <Grid container spacing={8}>
      <Grid item xs={8}>
        <Typography variant="h2" gutterBottom>
          {isCreateDriver ? 'Create' : 'Update'} Driver
        </Typography>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <Grid container spacing={8}>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    required
                    label="Driver’s HDMS Op ID"
                    name="driver_id"
                    variant="outlined"
                    onChange={handleChange}
                    disabled={!isCreateDriver}
                    helperText={errors.driver_id}
                    value={formData.driver_id || ''}
                    error={Boolean(errors.driver_id)}
                  />
                </Grid>
                {!isCreateDriver && (
                  <Grid item xs={8}>
                    <Grid container spacing={8}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          disabled
                          label="Phone Model"
                          name="phone_model"
                          variant="outlined"
                          onChange={handleChange}
                          value={formData.phone_model || ''}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          disabled
                          label="Phone OS"
                          name="phone_os"
                          variant="outlined"
                          onChange={handleChange}
                          value={formData.phone_os || ''}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={8}>
                  <Grid container spacing={8}>
                    <Grid item xs={isCreateDriver ? 12 : 6}>
                      <TextField
                        fullWidth
                        label="ID"
                        name="_id"
                        required
                        variant="outlined"
                        onChange={handleChange}
                        helperText={errors._id}
                        disabled={!isCreateDriver}
                        value={formData._id || ''}
                        error={Boolean(errors._id)}
                      />
                    </Grid>
                    {!isCreateDriver && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="UID"
                          name="u_id"
                          variant="outlined"
                          onChange={handleChange}
                          disabled={!isCreateDriver}
                          value={formData.u_id || ''}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    required
                    label="Name"
                    name="driver_nm"
                    variant="outlined"
                    onChange={handleChange}
                    helperText={errors.driver_nm}
                    value={formData.driver_nm || ''}
                    error={Boolean(errors.driver_nm)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    required
                    fullWidth
                    name="email"
                    label="Email"
                    variant="outlined"
                    onChange={handleChange}
                    helperText={errors.email}
                    value={formData.email || ''}
                    error={Boolean(errors.email)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    required
                    fullWidth
                    name="ph"
                    label="Phone"
                    variant="outlined"
                    helperText={errors.ph}
                    onChange={handleChange}
                    value={formData.ph || ''}
                    error={Boolean(errors.ph)}
                  />
                </Grid>

                <Grid item xs={8} style={{ position: 'relative' }}>
                  <InputLabel
                    htmlFor="select-multiple-chip"
                    className={
                      formData.dc_id ? classes.labelShrink : classes.label
                    }>
                    Location
                  </InputLabel>

                  <Select
                    fullWidth
                    name="dc_id"
                    value={formData.dc_id || ''}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        labelWidth={100}
                        id="select-multiple-chip"
                      />
                    }>
                    {dc.map((d) => {
                      return (
                        <MenuItem key={d._id} value={d.dc_id}>
                          {d.dc_nm}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={8} style={{ position: 'relative' }}>
                  <InputLabel
                    htmlFor="select-multiple-chip"
                    className={
                      formData.roles?.length
                        ? classes.labelShrink
                        : classes.label
                    }>
                    Roles
                  </InputLabel>

                  <Select
                    fullWidth
                    multiple
                    name="roles"
                    onChange={handleChange}
                    value={formData.roles || []}
                    input={
                      <OutlinedInput
                        labelWidth={100}
                        id="select-multiple-chip"
                      />
                    }
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                            onDelete={() => handleDeleteRole(value)}
                          />
                        ))}
                      </div>
                    )}
                    open={menuOpen}
                    MenuProps={MenuProps}
                    onOpen={() => setMenuOpen(true)}
                    onClose={() => setMenuOpen(false)}>
                    {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* <Grid item xs={8}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    onClick={submitDriverDetail}>
                    Change Password
                  </Button>
                </Grid> */}

                <Grid item xs={8}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    onClick={submitDriverDetail}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={6}>
              <Grid container spacing={8}>
                <Grid item xs={8}>
                  <Typography variant="h5" gutterBottom>
                    Change Password
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    disabled
                    label="Old Password"
                    name="o_pw"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.driver_id || ''}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    disabled
                    label="New Password"
                    name="n_pw"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.driver_id || ''}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    onClick={submitDriverDetail}>
                    Change Password
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    onClick={submitDriverDetail}
                    style={{ marginLeft: 10 }}>
                    Discard
                  </Button>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </form>
      )}
    </Grid>
  );
};

const mapDispatchToProps = {
  open_snack_ac,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(EditDriverForm));
